import React from 'react';

export const facebookIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="26"
      fill="none"
      viewBox="0 0 29 26"
    >
      <path fill="#fff" d="M0 0h23a6 6 0 016 6v20H6a6 6 0 01-6-6V0z"></path>
      <path
        fill="#2F8D9E"
        fillRule="evenodd"
        d="M18 6.108C17.75 6.072 16.889 6 15.889 6c-2.093 0-3.528 1.244-3.528 3.525v1.965H10v2.668h2.361V21h2.833v-6.842h2.352l.361-2.668h-2.713V9.786c0-.766.213-1.298 1.352-1.298H18v-2.38z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
