import React from 'react';

export const googleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="26"
      fill="none"
      viewBox="0 0 29 26"
    >
      <path fill="#fff" d="M0 0h23a6 6 0 016 6v20H6a6 6 0 01-6-6V0z"></path>
      <path
        fill="#2F8D9E"
        d="M22.375 12.188V9.562h-1.313v2.626h-2.625V13.5h2.625v2.625h1.313V13.5H25v-1.313h-2.625zM10.563 12.188v2.624h3.713a3.945 3.945 0 01-3.713 2.626A3.942 3.942 0 016.624 13.5a3.942 3.942 0 013.938-3.938 3.88 3.88 0 012.55.95l1.724-1.979a6.503 6.503 0 00-4.274-1.595A6.57 6.57 0 004 13.5a6.57 6.57 0 006.563 6.563 6.57 6.57 0 006.562-6.563v-1.313h-6.563z"
      ></path>
    </svg>
  );
};
